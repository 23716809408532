import { DateTime } from 'luxon';
import { Invoice, InvoiceState } from '../generated/types';
import { assertUnreachable } from '../util/assertUnreachable';
import { IBadgeColorIcon } from './volunteerRegistration';

export function getUrl(invoice: Pick<Invoice, 'id' | 'invoiceNumber'>) {
    return `https://invoices.recrewteer.com/${invoice.id}/%23${invoice.invoiceNumber}.pdf`;
}

export function getInvoiceState(
    invoice: Pick<Invoice, 'isRefunded' | 'paidAt' | 'insertedAt'>
): InvoiceState {
    if (invoice.isRefunded) {
        return InvoiceState.Refunded;
    } else if (invoice.paidAt) {
        return InvoiceState.Paid;
    } else if (invoice.insertedAt < DateTime.now().minus({ day: 31 })) {
        return InvoiceState.Overdue;
    } else {
        return InvoiceState.Waiting;
    }
}

export function getStateBadgeColorIcon(state: InvoiceState): IBadgeColorIcon {
    switch (state) {
        case InvoiceState.Refunded:
            return { color: 'warning', leftIcon: 'rotate-left' };
        case InvoiceState.Paid:
            return { color: 'success', leftIcon: 'check' };
        case InvoiceState.Overdue:
            return { color: 'error', leftIcon: 'alarm-clock' };
        case InvoiceState.Waiting:
            return { color: 'error', leftIcon: 'xmark' };
        default:
            return assertUnreachable(state);
    }
}
