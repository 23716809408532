import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { AccreditationCustomFields } from '../../accreditations/show/accreditationCustomFields';
import { AccreditationDescription } from '../../accreditations/show/accreditationDescription';
import { AccreditationLocation } from '../../accreditations/show/accreditationLocation';
import { AccreditationPrivateNote } from '../../accreditations/show/accreditationPrivateNote';
import { AccreditationTags } from '../../accreditations/show/accreditationTags';
import {
    usePositionLeadersAsignMutation,
    usePositionQuery,
    useTagsPositionAssociateMutation
} from '../../generated/graphqlHooks';
import { PositionCategoryGauge } from '../../positionsCategories/show/positionCategoryGauge';
import { PositionCategoryLeaders } from '../../positionsCategories/show/positionCategoryLeaders';
import { PositionHeader } from './positionHeader';
import { PositionParameters } from './positionParameters';
import { PositionSlotRightPanel } from './positionSlots/positionSlotRightPanel';
import { PositionSlots } from './positionSlots/positionSlots';

export const Position = () => {
    const {
        history,
        translate,
        params: { organizationId, eventId, positionId }
    } = useHeavent();
    const { data, loader, reload } = usePositionQuery({ eventId, positionId });
    const { mutate: leadersAssign } = usePositionLeadersAsignMutation();
    const { mutate: tagsAssociate } = useTagsPositionAssociateMutation();

    return (
        loader || (
            <>
                <Flex direction="column" width={1} css={{ padding: '$7' }}>
                    <PositionHeader position={data.event.position} />

                    <Spacer height="7" />

                    <Flex gap="6">
                        <PositionCategoryGauge
                            category={data.event.position}
                            title={translate('remplissage_de_52564')}
                        />

                        <PositionParameters position={data.event.position} />
                    </Flex>

                    <Spacer height="6" />

                    <PositionSlots />

                    <Spacer height="6" />

                    <Flex gap="6">
                        <Flex direction="column" gap="4" width={1}>
                            <PositionCategoryLeaders
                                leaders={data.event.position.leaders}
                                onSave={(leaders) =>
                                    leadersAssign({
                                        eventId,
                                        positionId,
                                        leaders
                                    })
                                }
                                reload={reload}
                            />

                            <AccreditationCustomFields
                                customFields={data.event.position.positionsCustomFields}
                            />

                            <AccreditationTags
                                tags={data.event.position.tags}
                                type="positions"
                                associateTags={(tagsIds) =>
                                    tagsAssociate({
                                        eventId,
                                        positionId,
                                        tagsIds
                                    })
                                }
                                reload={reload}
                            />
                        </Flex>

                        <Flex direction="column" gap="4" width={1}>
                            <AccreditationDescription accreditation={data.event.position} />

                            <AccreditationLocation accreditation={data.event.position} />

                            <AccreditationPrivateNote accreditation={data.event.position} />
                        </Flex>
                    </Flex>
                </Flex>

                <Switch>
                    <Route
                        path={HeaventPaths.POSITION_SLOT_MEMBERS(
                            ':organizationId',
                            ':eventId',
                            ':positionId',
                            ':positionSlotId'
                        )}
                    >
                        <PositionSlotRightPanel
                            onClose={() => {
                                history.push(
                                    HeaventPaths.POSITION(organizationId, eventId, positionId)
                                );
                            }}
                        />
                    </Route>
                </Switch>
            </>
        )
    );
};
