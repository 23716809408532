import { FileInput, FileInputValue } from 'common-front/src/designSystem/components/file/fileInput';
import { Textarea } from 'common-front/src/designSystem/components/textarea';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Accept } from 'common-front/src/util/accept';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { UserCertificate } from 'common/src/documents/userCertificate/userCertificate';
import { DocumentType, UserCertificateDocumentQuery } from 'common/src/generated/types';
import { getDocumentFullPath } from 'common/src/util/documentsPaths';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { getDefaultCertificateOptions, getDefaultFileOptions } from 'common/src/vo/eventDocument';
import * as React from 'react';
import { useUserCertificateDocumentQuery } from '../../generated/graphqlHooks';
import { DocumentHeader } from './documentHeader';
import { DocumentOptions, useDocumentOptions } from './documentOptions';
import { DocumentsAssignedUserSelector } from './documentsAssignedUserSelector';
import { DocumentShadow } from './documentShadow';

interface ICertificateDocumentContentProps {
    data: UserCertificateDocumentQuery;
}

const CertificateDocumentContent = (props: ICertificateDocumentContentProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const [options, setOptions] = useDocumentOptions(
        props.data.event.document.options,
        getDefaultCertificateOptions(props.data.organization),
        eventId,
        props.data.event.document.id
    );
    const ui = props.data.event.volunteerRegistration.userInfo;

    return (
        <Flex css={{ flex: '1', overflow: 'hidden' }} gap="7" width={1}>
            <DocumentOptions>
                <TextInput
                    label={translate('full_name')}
                    placeholder="Antoine Dupont"
                    value={options.name}
                    onChange={(name: string) => {
                        setOptions({ name });
                    }}
                />

                <TextInput
                    label={translate('fonction_68322')}
                    placeholder={translate('pr_sident_30600')}
                    value={options.title}
                    onChange={(title: string) => {
                        setOptions({ title });
                    }}
                />

                <Textarea
                    label={translate('nom_et_adresse_81953')}
                    value={options.address}
                    onChange={(address: string) => {
                        setOptions({ address });
                    }}
                />

                <Textarea
                    label={translate('informations_su_09057')}
                    value={options.infos}
                    onChange={(infos: string) => {
                        setOptions({ infos });
                    }}
                />

                <TextInput
                    label={translate('lieu_de_l_attes_84804')}
                    placeholder="Montpellier"
                    value={options.place}
                    onChange={(place: string) => {
                        setOptions({ place });
                    }}
                />

                <Flex align="center" gap="3">
                    <FileInput
                        accept={Accept.Images}
                        format="base64"
                        hint={translate('taille_de_l_ima_12660', '682x260px')}
                        label={translate('en_t_te_11987')}
                        placeholder={translate('en_t_te_11987')}
                        value={options.header}
                        onChange={(header: FileInputValue) => {
                            setOptions({ header });
                        }}
                    />

                    {isNonEmptyString(options.header?.name) && (
                        <Box
                            css={{ cursor: 'pointer' }}
                            font="gray700 textSm medium"
                            onClick={() => {
                                setOptions({ header: getDefaultFileOptions() });
                            }}
                        >
                            {translate('r_initialiser_77538')}
                        </Box>
                    )}
                </Flex>

                <Flex align="center" gap="3">
                    <FileInput
                        accept={Accept.Images}
                        format="base64"
                        label={translate('signature_67521')}
                        placeholder={translate('signature_67521')}
                        value={options.signature}
                        onChange={(signature: FileInputValue) => {
                            setOptions({ signature });
                        }}
                    />

                    {isNonEmptyString(options.signature?.name) && (
                        <Box
                            css={{ cursor: 'pointer' }}
                            font="gray700 textSm medium"
                            onClick={() => {
                                setOptions({ signature: getDefaultFileOptions() });
                            }}
                        >
                            {translate('r_initialiser_77538')}
                        </Box>
                    )}
                </Flex>
            </DocumentOptions>

            <Flex css={{ flex: '1' }} direction="column">
                <DocumentsAssignedUserSelector
                    downloadPath={getDocumentFullPath(ui.links?.userCertificateLink ?? '', 'pdf')}
                    getPath={(newUserInfoId) =>
                        HeaventPaths.DOCUMENTS_CERTIFICATE(organizationId, eventId, newUserInfoId)
                    }
                />

                <DocumentShadow>
                    <UserCertificate
                        certificateDocumentFragment={props.data.event}
                        options={options}
                    />
                </DocumentShadow>
            </Flex>
        </Flex>
    );
};

export const UserCertificateDocument = () => {
    const {
        translate,
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();
    const { data, loader } = useUserCertificateDocumentQuery({
        organizationId,
        eventId,
        userInfoId
    });

    return (
        <Flex css={{ padding: '$7' }} direction="column" height={1} width={1}>
            <DocumentHeader
                documentType={DocumentType.Certificate}
                eventId={eventId}
                languages={data.event?.languages ?? []}
                organizationId={organizationId}
                segments={data.event?.segments ?? []}
                title={translate('attestations_98371')}
            />

            <Spacer height="7" />

            {loader || <CertificateDocumentContent data={data} />}
        </Flex>
    );
};
