import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EmailsSendersQuery, OrganizationId } from 'common/src/generated/types';
import * as React from 'react';
import { EmailSenderRow } from './emailSenderRow';

interface IOrganizationEmailsSendersListProps {
    emailsSenders: EmailsSendersQuery['organization']['emailsSenders'];
    organizationId: OrganizationId;

    reload(): void;
}

export const OrganizationEmailsSendersList = (props: IOrganizationEmailsSendersListProps) => (
        <>
            <Spacer height="7" />

            <Box font="gray900 textLg medium">Liste des émetteurs d&apos;e-mails</Box>

            <Spacer height="2" />

            <Flex
                direction="column"
                css={{
                    border: '1px solid $gray200',
                    borderRadius: '$2',
                    '& > div:not(:last-child)': {
                        borderBottom: '1px solid $gray200'
                    }
                }}
            >
                {props.emailsSenders.map((emailSender) => (
                        <EmailSenderRow
                            key={emailSender.id}
                            emailSender={emailSender}
                            organizationId={props.organizationId}
                            reload={props.reload}
                        />
                    ))}
            </Flex>
        </>
    );
