import { FileInput, FileInputValue } from 'common-front/src/designSystem/components/file/fileInput';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Accept } from 'common-front/src/util/accept';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { UserAssignments } from 'common/src/documents/userAssignments/userAssignments';
import { DocumentType, UserAssignmentsDocumentQuery } from 'common/src/generated/types';
import { getDocumentFullPath } from 'common/src/util/documentsPaths';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import {
    getDefaultFileOptions,
    getDefaultUserAssignmentsOptions
} from 'common/src/vo/eventDocument';
import * as React from 'react';
import { useUserAssignmentsDocumentQuery } from '../../generated/graphqlHooks';
import { DocumentHeader } from './documentHeader';
import { DocumentOptions, useDocumentOptions } from './documentOptions';
import { DocumentsAssignedUserSelector } from './documentsAssignedUserSelector';
import { DocumentShadow } from './documentShadow';

interface IUserAssignmentsContentProps {
    data: UserAssignmentsDocumentQuery;
}

const UserAssignmentsContent = (props: IUserAssignmentsContentProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const [options, setOptions] = useDocumentOptions(
        props.data.event.document.options,
        getDefaultUserAssignmentsOptions(),
        eventId,
        props.data.event.document.id
    );
    const ui = props.data.organization.userInfo;

    return (
        <Flex css={{ flex: '1', overflow: 'hidden' }} gap="7" width={1}>
            <DocumentOptions>
                <Select
                    label={translate('donn_es_des_res_45809')}
                    value={options.leadersData}
                    onChange={(newLeadersData) => {
                        setOptions({ leadersData: newLeadersData });
                    }}
                >
                    <option value="both">{translate('afficher_le_num_37296')}</option>
                    <option value="phone">{translate('afficher_unique_41829')}</option>
                    <option value="email">{translate('afficher_unique_97964')}</option>
                    <option value="none">{translate('afficher_aucune_38167')}</option>
                </Select>

                <TextInput
                    label={translate('titre_du_docume_33508')}
                    placeholder={translate('titre_du_docume_33508')}
                    value={options.headerText}
                    onChange={(headerText: string) => {
                        setOptions({ headerText });
                    }}
                />

                <Flex align="center" gap="3">
                    <FileInput
                        accept={Accept.Images}
                        format="base64"
                        hint={translate('taille_de_l_ima_12660', '682x210px')}
                        label={translate('en_t_te_11987')}
                        placeholder={translate('en_t_te_11987')}
                        value={options.header}
                        onChange={(header: FileInputValue) => {
                            setOptions({ header });
                        }}
                    />

                    {isNonEmptyString(options.header?.name) && (
                        <Box
                            css={{ cursor: 'pointer' }}
                            font="gray700 textSm medium"
                            onClick={() => {
                                setOptions({ header: getDefaultFileOptions() });
                            }}
                        >
                            {translate('r_initialiser_77538')}
                        </Box>
                    )}
                </Flex>
            </DocumentOptions>

            <Flex css={{ flex: '1' }} direction="column">
                <DocumentsAssignedUserSelector
                    downloadPath={getDocumentFullPath(ui.links?.userAssignmentsLink ?? '', 'pdf')}
                    getPath={(userInfoId) =>
                        HeaventPaths.DOCUMENTS_ASSIGNMENTS(organizationId, eventId, userInfoId)
                    }
                />

                <DocumentShadow>
                    <UserAssignments assignmentsInfos={props.data} options={options} />
                </DocumentShadow>
            </Flex>
        </Flex>
    );
};

export const UserAssignmentsDocument = () => {
    const {
        translate,
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();
    const { data, loader } = useUserAssignmentsDocumentQuery({
        organizationId,
        eventId,
        userInfoId
    });

    return (
        <Flex css={{ padding: '$7' }} direction="column" height={1} width={1}>
            <DocumentHeader
                documentType={DocumentType.Assignments}
                endAt={data.event?.endAt}
                eventId={eventId}
                languages={data.event?.languages ?? []}
                organizationId={organizationId}
                segments={data.event?.segments ?? []}
                showDate={true}
                startAt={data.event?.startAt}
                title={translate('fiches_d_affect_98583')}
            />

            <Spacer height="7" />

            {loader || <UserAssignmentsContent data={data} />}
        </Flex>
    );
};
