import { omit } from 'lodash-es';
import * as React from 'react';
import { DesignSytemColor } from '../util/color';
import {
    convertFontStringToProperties,
    DesignSystemFont,
    DesignSystemFontSize,
    DesignSystemFontWeight
} from '../util/font';
import { convertToPercentOrPx } from '../util/unit';
import { ILinkProps, Link } from './link';
import { CSS, ResponsiveProperty, styled } from './stitches';

const _Box = styled('div', {
    variants: {
        color: {
            inherit: { color: 'inherit' },
            cyan: { color: '$cyan' },
            gray25: { color: '$gray25' },
            gray50: { color: '$gray50' },
            gray100: { color: '$gray100' },
            gray200: { color: '$gray200' },
            gray300: { color: '$gray300' },
            gray400: { color: '$gray400' },
            gray500: { color: '$gray500' },
            gray600: { color: '$gray600' },
            gray700: { color: '$gray700' },
            gray800: { color: '$gray800' },
            gray900: { color: '$gray900' },
            primary25: { color: '$primary25' },
            primary50: { color: '$primary50' },
            primary100: { color: '$primary100' },
            primary200: { color: '$primary200' },
            primary300: { color: '$primary300' },
            primary400: { color: '$primary400' },
            primary500: { color: '$primary500' },
            primary600: { color: '$primary600' },
            primary700: { color: '$primary700' },
            primary800: { color: '$primary800' },
            primary900: { color: '$primary900' },
            error25: { color: '$error25' },
            error50: { color: '$error50' },
            error100: { color: '$error100' },
            error200: { color: '$error200' },
            error300: { color: '$error300' },
            error400: { color: '$error400' },
            error500: { color: '$error500' },
            error600: { color: '$error600' },
            error700: { color: '$error700' },
            error800: { color: '$error800' },
            error900: { color: '$error900' },
            warning25: { color: '$warning25' },
            warning50: { color: '$warning50' },
            warning100: { color: '$warning100' },
            warning200: { color: '$warning200' },
            warning300: { color: '$warning300' },
            warning400: { color: '$warning400' },
            warning500: { color: '$warning500' },
            warning600: { color: '$warning600' },
            warning700: { color: '$warning700' },
            warning800: { color: '$warning800' },
            warning900: { color: '$warning900' },
            success25: { color: '$success25' },
            success50: { color: '$success50' },
            success100: { color: '$success100' },
            success200: { color: '$success200' },
            success300: { color: '$success300' },
            success400: { color: '$success400' },
            success500: { color: '$success500' },
            success600: { color: '$success600' },
            success700: { color: '$success700' },
            success800: { color: '$success800' },
            success900: { color: '$success900' },
            blue25: { color: '$blue25' },
            blue50: { color: '$blue50' },
            blue100: { color: '$blue100' },
            blue200: { color: '$blue200' },
            blue300: { color: '$blue300' },
            blue400: { color: '$blue400' },
            blue500: { color: '$blue500' },
            blue600: { color: '$blue600' },
            blue700: { color: '$blue700' },
            blue800: { color: '$blue800' },
            blue900: { color: '$blue900' },
            blueGray25: { color: '$blueGray25' },
            blueGray50: { color: '$blueGray50' },
            blueGray100: { color: '$blueGray100' },
            blueGray200: { color: '$blueGray200' },
            blueGray300: { color: '$blueGray300' },
            blueGray400: { color: '$blueGray400' },
            blueGray500: { color: '$blueGray500' },
            blueGray600: { color: '$blueGray600' },
            blueGray700: { color: '$blueGray700' },
            blueGray800: { color: '$blueGray800' },
            blueGray900: { color: '$blueGray900' },
            blueLight100: { color: '$blueLight100' },
            blueLight400: { color: '$blueLight400' },
            blueLight600: { color: '$blueLight600' },
            blueLight900: { color: '$blueLight900' },
            purple25: { color: '$purple25' },
            purple50: { color: '$purple50' },
            purple100: { color: '$purple100' },
            purple200: { color: '$purple200' },
            purple300: { color: '$purple300' },
            purple400: { color: '$purple400' },
            purple500: { color: '$purple500' },
            purple600: { color: '$purple600' },
            purple700: { color: '$purple700' },
            purple800: { color: '$purple800' },
            purple900: { color: '$purple900' },
            pink25: { color: '$pink25' },
            pink50: { color: '$pink50' },
            pink100: { color: '$pink100' },
            pink200: { color: '$pink200' },
            pink300: { color: '$pink300' },
            pink400: { color: '$pink400' },
            pink500: { color: '$pink500' },
            pink600: { color: '$pink600' },
            pink700: { color: '$pink700' },
            pink800: { color: '$pink800' },
            pink900: { color: '$pink900' },
            orange100: { color: '$orange100' },
            orange400: { color: '$orange400' },
            orange600: { color: '$orange600' },
            indigo100: { color: '$indigo100' },
            indigo600: { color: '$indigo600' },
            white: { color: 'white' }
        },
        fontSize: {
            inherit: { fontSize: 'inherit' },
            textXs: { fontSize: '$textXs' },
            textSm: { fontSize: '$textSm' },
            textMd: { fontSize: '$textMd' },
            textLg: { fontSize: '$textLg' },
            textXl: { fontSize: '$textXl' },
            displayXs: { fontSize: '$displayXs' },
            displaySm: { fontSize: '$displaySm' },
            displayMd: { fontSize: '$displayMd' },
            displayLg: { fontSize: '$displayLg' },
            displayXl: { fontSize: '$displayXl' },
            display2Xl: { fontSize: '$display2Xl' }
        },
        fontWeight: {
            inherit: { fontWeight: 'inherit' },
            regular: { fontWeight: '$regular' },
            medium: { fontWeight: '$medium' },
            semiBold: { fontWeight: '$semiBold' },
            bold: { fontWeight: '$bold' }
        },
        textAlign: {
            start: { textAlign: 'start' },
            end: { textAlign: 'end' },
            center: { textAlign: 'center' },
            justify: { textAlign: 'justify' }
        },
        ellipsis: {
            true: {
                ellipsis: ''
            }
        }
    },
    defaultVariants: {
        color: 'inherit',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        textAlign: 'start'
    }
});

interface IBoxProps
    extends Omit<React.HTMLProps<HTMLDivElement>, 'color' | 'target'>,
        Partial<Pick<ILinkProps, 'to' | 'target' | 'download'>> {
    color?: ResponsiveProperty<DesignSytemColor>;
    font?: DesignSystemFont;
    fontSize?: ResponsiveProperty<DesignSystemFontSize>;
    fontWeight?: ResponsiveProperty<DesignSystemFontWeight>;
    textAlign?: ResponsiveProperty<'start' | 'end' | 'center' | 'justify'>;
    ellipsis?: boolean;
    css?: CSS;
    height?: number | string;
    width?: number | string;
    className?: string;
    children?: React.ReactNode;
}

export const Box = React.forwardRef((props: IBoxProps, ref: React.Ref<HTMLDivElement>) => {
    const css: any = {};

    const renderElement = () => (
        <_Box
            {...omit(props, 'font')}
            {...convertFontStringToProperties(props.font)}
            ref={ref}
            css={{
                ...css,
                ...props.css
            }}
        />
    );

    if (props.height) {
        css.height = convertToPercentOrPx(props.height);
    }

    if (props.width) {
        css.width = convertToPercentOrPx(props.width);
    }

    return props.to ? (
        <Link to={props.to} target={props.target} height={props.height} download={props.download}>
            {renderElement()}
        </Link>
    ) : (
        renderElement()
    );
});
Box.displayName = 'Box';
