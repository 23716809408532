import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import { BackofficeInvoicesQuery, InvoicesQuery } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { getInvoiceState, getStateBadgeColorIcon } from 'common/src/vo/invoice';
import * as React from 'react';
import { BackofficeInvoicesColumns } from '../../backoffice/invoices/backofficeInvoicesColumns';

type BackofficeProps = {
    invoice: BackofficeInvoicesQuery['backoffice']['invoices']['nodes'][0];
    isBackoffice: true;

    markAsPaid(): void;
    showColumn(column: BackofficeInvoicesColumns): boolean;
};

type InvoiceRowProps = {
    invoice: InvoicesQuery['organization']['invoices'][0];
    isBackoffice: false;

    showColumn(column: BackofficeInvoicesColumns): boolean;
};

type IInvoiceRowProps = BackofficeProps | InvoiceRowProps;

export const InvoiceRow = (props: IInvoiceRowProps) => {
    const translate = useTranslate();
    const dateTimeService = useService(DateTimeService);
    const formatter = new Intl.NumberFormat('fr-fr', { style: 'currency', currency: 'EUR' });
    const invoiceState = getInvoiceState(props.invoice);

    return (
        <Row>
            <Cell>
                <Box color="gray900" fontWeight="medium">
                    {translate(
                        'facture_1_87704',
                        props.invoice.invoiceNumber,
                        props.invoice.insertedAt.year
                    )}
                </Box>
            </Cell>
            {props.isBackoffice && props.showColumn(BackofficeInvoicesColumns.Organization) && (
                <Cell>
                    <Box
                        css={{ ellipsis: true }}
                        title={props.invoice.organizationsNames}
                        width={1}
                    >
                        {props.invoice.organizationsNames}
                    </Box>
                </Cell>
            )}
            {props.showColumn(BackofficeInvoicesColumns.Date) && (
                <Cell>
                    {dateTimeService.toLocaleString(
                        props.invoice.insertedAt,
                        LocaleFormats.DateOnly.MonthLong
                    )}
                </Cell>
            )}
            {props.showColumn(BackofficeInvoicesColumns.Amount) && (
                <Cell>{formatter.format(props.invoice.amountCents / 100)}</Cell>
            )}
            {props.showColumn(BackofficeInvoicesColumns.State) && (
                <Cell>
                    <Badge {...getStateBadgeColorIcon(invoiceState)}>
                        {translate(invoiceState)}
                    </Badge>
                </Cell>
            )}
            {props.isBackoffice && props.showColumn(BackofficeInvoicesColumns.TransactionId) && (
                <Cell>{props.invoice.dealId}</Cell>
            )}
            <Cell>
                <Box
                    color="primary700"
                    css={{ cursor: 'pointer' }}
                    fontWeight="medium"
                    onClick={() => {
                        window.open(props.invoice.url);
                    }}
                >
                    {translate('t_l_charger_03283')}
                </Box>
            </Cell>
            {props.isBackoffice && (
                <Cell>
                    {!props.invoice.paidAt && (
                        <Box
                            color="primary700"
                            css={{ cursor: 'pointer' }}
                            fontWeight="medium"
                            onClick={() => {
                                props.markAsPaid?.();
                            }}
                        >
                            {translate('marqu_e_comme_p_72620')}
                        </Box>
                    )}
                </Cell>
            )}
        </Row>
    );
};
