import { uniq, uniqWith } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { styled } from '../../../designSystem/components/stitches';
import { CommonEnvVars } from '../../../envVars';
import { AccreditationId, DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

const getBackground = (accredId: AccreditationId) => {
    switch (accredId) {
        case 3312:
            return 'https://assets.recrewteer.com/badges/res/2024/Parking_L.jpg';
        case 2813:
            return 'https://assets.recrewteer.com/badges/res/2024/Parking_PA.jpg';
        case 2814:
            return 'https://assets.recrewteer.com/badges/res/2024/Parking_C1.jpg';
        case 2815:
            return 'https://assets.recrewteer.com/badges/res/2024/Parking_C2.jpg';
        case 2818:
            return 'https://assets.recrewteer.com/badges/res/2024/Parking_PG.jpg';
        case 2817:
            return 'https://assets.recrewteer.com/badges/res/2024/Parking_IM.jpg';
        case 2816:
            return 'https://assets.recrewteer.com/badges/res/2024/Parking_MS.jpg';
        case 2799:
            return 'https://assets.recrewteer.com/badges/res/2024/Parking_P1.jpg';
        case 2809:
            return 'https://assets.recrewteer.com/badges/res/2024/Parking_P2.jpg';
        case 2810:
            return 'https://assets.recrewteer.com/badges/res/2024/Parking_P3.jpg';
        case 2811:
            return 'https://assets.recrewteer.com/badges/res/2024/Parking_P4.jpg';
        default: /* 2812 */
            return 'https://assets.recrewteer.com/badges/res/2024/Parking_PS.jpg';
    }
};

const BorderedBox = styled('div', {
    alignItems: 'center',
    border: '1px solid',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '8px'
});

const Page = ({
    event,
    accredId
}: {
    event: DocumentUserCustomBadgeFragment;
    accredId: AccreditationId;
}) => {
    const vr = event.volunteerRegistration;
    const ui = vr.userInfo;
    const dates = uniqWith(
        vr.accreditationsUsersInfos.flatMap((aui) => {
            if (aui.accreditation.id === accredId && aui.accreditationSlot.date) {
                return aui.accreditationSlot.date;
            } else {
                return [];
            }
        }),
        (dt1, dt2) => +dt1 === +dt2
    ).sort((dt1, dt2) => (dt1 < dt2 ? -1 : dt1 > dt2 ? 1 : 0));

    return (
        <Flex
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
            css={{
                background: `url(${getBackground(accredId)}) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$rubik',
                position: 'relative'
            }}
        >
            <Box
                css={{
                    gap: '6px',
                    fontFamily: '$bnCartaNoirBold',
                    fontSize: '36px',
                    fontWeight: 700,
                    left: '48px',
                    lineHeight: 'normal',
                    position: 'absolute',
                    top: '312px'
                }}
            >
                {ui.fields.cf33266 || ''}
            </Box>
            <Flex
                direction="column"
                align="center"
                css={{ left: '636px', gap: '4px', position: 'absolute', top: '312px' }}
            >
                <Box height={100} width={100}>
                    {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                        <img
                            src={`https://api.${
                                CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                            }/barcode/qr?text=${encodeURIComponent(
                                vr.weezeventParticipantInfo!.idBarcode
                            )}&color=000000&height=100&width=100`}
                            height="100%"
                            width="100%"
                        />
                    )}
                </Box>
                <Box
                    css={{
                        fontSize: '10px',
                        lineHeight: 'normal',
                        textTransform: 'uppercase'
                    }}
                >
                    {vr.weezeventParticipantInfo?.idBarcode ?? ''}
                </Box>
            </Flex>
            <Flex
                css={{
                    gap: '6px',
                    left: '48px',
                    position: 'absolute',
                    top: '402px'
                }}
            >
                {dates.map((date, i) => (
                    <BorderedBox key={i}>
                        <Box css={{ fontSize: '14px', fontWeight: 700, lineHeight: 'normal' }}>
                            {date.day}
                        </Box>
                        <Box
                            style={{
                                fontSize: '9px',
                                lineHeight: 'normal',
                                textTransform: 'uppercase'
                            }}
                        >
                            {date.setLocale('fr-FR').monthLong}
                        </Box>
                    </BorderedBox>
                ))}
            </Flex>
            <Box
                css={{
                    gap: '6px',
                    fontSize: '20px',
                    fontWeight: 700,
                    left: '48px',
                    lineHeight: 'normal',
                    position: 'absolute',
                    top: '504px',
                    width: '222px'
                }}
            >
                {ui.firstName}
            </Box>
            <Box
                css={{
                    gap: '6px',
                    fontSize: '20px',
                    fontWeight: 700,
                    left: '48px',
                    lineHeight: 'normal',
                    position: 'absolute',
                    top: '534px',
                    width: '222px'
                }}
            >
                {ui.lastName}
            </Box>
            <Box
                css={{
                    gap: '6px',
                    fontSize: '20px',
                    fontWeight: 700,
                    left: '286px',
                    lineHeight: 'normal',
                    position: 'absolute',
                    top: '504px'
                }}
            >
                {ui.phone?.internationalFormat}
            </Box>
            <Box
                css={{
                    gap: '6px',
                    fontSize: '20px',
                    fontWeight: 700,
                    left: '524px',
                    lineHeight: 'normal',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '504px',
                    width: '222px'
                }}
            >
                {vr.delegation?.name ?? ''}
            </Box>
        </Flex>
    );
};

export const BadgeRes2024Parking = ({ event }: { event: DocumentUserCustomBadgeFragment }) => {
    const parkings = [
        3312, 2813, 2814, 2815, 2818, 2817, 2816, 2799, 2809, 2810, 2811, 2812
    ] as AccreditationId[];
    const vr = event.volunteerRegistration;
    const accreds = uniq(
        vr.accreditationsUsersInfos.reduce((acc, aui) => {
            if (parkings.includes(aui.accreditation.id)) {
                return [...acc, aui.accreditation.id];
            } else {
                return acc;
            }
        }, [] as AccreditationId[])
    );

    return (
        <>
            {accreds.map((accredId) => (
                <Page key={accredId} event={event} accredId={accredId} />
            ))}
        </>
    );
};
