import { sortBy, uniqBy } from 'lodash-es';
import { DateTime } from 'luxon';
import * as React from 'react';
import Snowflake from 'svgComponents/fontawesome/snowflake.svg';
import Star from 'svgComponents/fontawesome/star.svg';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { Spacer } from '../../../designSystem/components/spacer';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { DateTimeService } from '../../../services/dateTimeService';
import { useService } from '../../../util/dependencies/dependencies';
import { LocaleFormats } from '../../../util/luxon';
import { A6_SIZES } from '../../../util/pdf';
import { HighfiveEmptyZone } from './highfiveEmptyZone';
import { HighfiveZone } from './highfiveZone';

interface IBadgeHighfiveA6Props {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeHighfiveA6 = (props: IBadgeHighfiveA6Props) => {
    const dateTimeService = useService(DateTimeService);
    const volunteerRegistration = props.event.volunteerRegistration;
    const userInfo = volunteerRegistration.userInfo;
    const dateOfBirth = DateTime.fromISO(userInfo.fields.dateOfBirth);
    const isOfAge = dateOfBirth.isValid
        ? Math.abs(dateOfBirth.diffNow().shiftTo('years').years) >= 18
        : false;
    const acronyms = volunteerRegistration.accreditationsUsersInfos.map(
        ({ accreditation }) => accreditation.acronym
    );
    const hasBc = acronyms.includes('BC');
    const hasCon = acronyms.includes('CON');
    const hasBack = acronyms.includes('BACK');
    const hasHot = acronyms.includes('HOT');
    const hasFilm = acronyms.includes('FILM');
    const assignments = sortBy(
        uniqBy(
            volunteerRegistration.positionsSlotsUsersInfos.map(({ positionSlot }) =>
                positionSlot.range.start!.startOf('day')
            ),
            (d) => d.toMillis()
        ),
        (d) => d.toMillis()
    );

    return (
        <Flex height={A6_SIZES['96dpi'].height} width={A6_SIZES['96dpi'].width * 2}>
            <Flex
                direction="column"
                height={A6_SIZES['96dpi'].height}
                width={A6_SIZES['96dpi'].width}
                css={{ background: 'white' }}
            >
                <Box height={210} width={1}>
                    <img
                        src="https://assets.recrewteer.com/badges/highfive/highfiveHeader.png"
                        height="100%"
                        width="100%"
                    />
                </Box>

                <Spacer height="3" />

                <Flex align="center" justify="center" width={1}>
                    <Flex direction="column" align="center" width={1}>
                        <Box color="gray500" fontSize="textXl">
                            Staff
                        </Box>

                        <Spacer height="1" />

                        <Box color="gray800" fontSize="textLg" fontWeight="bold">
                            {userInfo.name}
                        </Box>

                        <Spacer height="1" />

                        <Flex gap="5">
                            {volunteerRegistration.isLeader && (
                                <Box
                                    height={14}
                                    width={14}
                                    css={{
                                        svg: {
                                            fill: '$warning400',
                                            height: '100%',
                                            width: '100%'
                                        }
                                    }}
                                >
                                    <Star />
                                </Box>
                            )}

                            {!isOfAge && (
                                <Box
                                    height={14}
                                    width={14}
                                    css={{
                                        svg: {
                                            fill: '$primary700',
                                            height: '100%',
                                            width: '100%'
                                        }
                                    }}
                                >
                                    <Snowflake />
                                </Box>
                            )}

                            {!volunteerRegistration.isLeader && isOfAge && <Box height={14} />}
                        </Flex>
                    </Flex>
                </Flex>

                <Spacer height="6" />

                <Flex
                    css={{
                        px: '$7'
                    }}
                >
                    <Flex direction="column" gap="3" width={0.7}>
                        <Box
                            color="gray600"
                            css={{
                                fontSize: '8px',
                                textTransform: 'uppercase'
                            }}
                        >
                            Zone(s) autorisée(s)
                        </Box>

                        {hasBc ? (
                            <HighfiveZone
                                color="#32d583"
                                name="Camp de base"
                                src="https://assets.recrewteer.com/badges/highfive/highfiveCircus.png"
                            />
                        ) : (
                            <HighfiveEmptyZone />
                        )}

                        {hasCon ? (
                            <HighfiveZone
                                color="#fd853a"
                                name="Concert"
                                src="https://assets.recrewteer.com/badges/highfive/highfiveGuitar.png"
                            />
                        ) : (
                            <HighfiveEmptyZone />
                        )}

                        {hasBack ? (
                            <HighfiveZone
                                color="#36bffa"
                                name="Backstage"
                                src="https://assets.recrewteer.com/badges/highfive/highfiveMask.png"
                            />
                        ) : (
                            <HighfiveEmptyZone />
                        )}

                        {hasHot ? (
                            <HighfiveZone
                                color="#0b4a6f"
                                name="Festival off"
                                src="https://assets.recrewteer.com/badges/highfive/highfiveOff.png"
                            />
                        ) : (
                            <HighfiveEmptyZone />
                        )}

                        {hasFilm ? (
                            <HighfiveZone
                                color="#f670c7"
                                name="Film"
                                src="https://assets.recrewteer.com/badges/highfive/highfiveFilm.png"
                            />
                        ) : (
                            <HighfiveEmptyZone />
                        )}
                    </Flex>

                    <Flex direction="column" gap="3" width={0.3}>
                        <Box
                            color="gray600"
                            css={{
                                fontSize: '8px',
                                textTransform: 'uppercase'
                            }}
                        >
                            Date(s) d&apos;accréditation
                        </Box>

                        {assignments.map((assignment, index) => (
                                <Box
                                    key={index}
                                    color="gray800"
                                    fontSize="textXs"
                                    fontWeight="bold"
                                >
                                    {dateTimeService.toLocaleString(
                                        assignment,
                                        LocaleFormats.DateOnly.MonthShort
                                    )}
                                </Box>
                            ))}
                    </Flex>
                </Flex>
            </Flex>

            <Flex
                direction="column"
                gap="3"
                height={A6_SIZES['96dpi'].height}
                width={A6_SIZES['96dpi'].width}
                css={{ background: 'white' }}
            >
                <Flex justify="center" width={1} css={{ paddingTop: '56px' }}>
                    <Box height={198} width={160}>
                        <img
                            src="https://assets.recrewteer.com/badges/highfive/highfiveQrCode.png"
                            height="100%"
                            width="100%"
                        />
                    </Box>
                </Flex>

                <Flex
                    direction="column"
                    gap="2"
                    css={{
                        marginTop: '88px',
                        px: '$7'
                    }}
                >
                    <Box color="gray800" fontWeight="bold">
                        Numéros d&apos;urgence
                    </Box>

                    <Box color="gray800" fontSize="textXs">
                        Secours / médical : 15
                    </Box>

                    <Box color="gray800" fontSize="textXs">
                        Police : 17
                    </Box>

                    <Box color="gray800" fontSize="textXs">
                        Bénévole : 07.87.69.60.96
                    </Box>

                    <Box color="gray800" fontSize="textXs">
                        Si tu n&apos;as besoin de rien, appelle Mathieu
                    </Box>
                </Flex>
            </Flex>
        </Flex>
    );
};
