import { chunk } from 'lodash-es';
import * as React from 'react';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgesFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { BadgeSalamandreA7Back } from './badgeSalamandreA7Back';
import { BagdeSalamandreA7Front } from './bagdeSalamandreA7Front';

interface IBadgesSalamandreProps {
    event: DocumentUserCustomBadgesFragment;
}

export const BadgesSalamandre = (props: IBadgesSalamandreProps) => (
        <>
            {chunk(props.event.volunteersRegistrations.nodes, 8).map(
                (volunteersRegistrations, index) => (
                        <React.Fragment key={`page-${index}`}>
                            <Flex
                                wrap="wrap"
                                height={1}
                                width={A4_SIZES['96dpi'].height}
                                css={{
                                    overflow: 'hidden',
                                    pageBreakBefore: index !== 0 ? 'always' : undefined
                                }}
                            >
                                {volunteersRegistrations.map((volunteerRegistration) => (
                                        <BagdeSalamandreA7Front
                                            key={`front-${volunteerRegistration.userInfo.id}`}
                                            event={{
                                                ...props.event,
                                                volunteerRegistration
                                            }}
                                        />
                                    ))}
                            </Flex>

                            <Flex
                                wrap="wrap"
                                height={1}
                                width={A4_SIZES['96dpi'].height}
                                css={{
                                    overflow: 'hidden',
                                    pageBreakBefore: 'always'
                                }}
                            >
                                {chunk(volunteersRegistrations, 4).map((vrs) => (
                                        <Flex 
                                            key={`group-${vrs[0].utid}`}
                                            direction="rowReverse"
                                            width={1}
                                        >
                                            {vrs.map((volunteerRegistration) => (
                                                    <BadgeSalamandreA7Back
                                                        key={`back-${volunteerRegistration.userInfo.id}`}
                                                        event={{
                                                            ...props.event,
                                                            volunteerRegistration
                                                        }}
                                                    />
                                                ))}
                                        </Flex>
                                    ))}
                            </Flex>
                        </React.Fragment>
                    )
            )}
        </>
    );
