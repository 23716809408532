import { Button } from 'common-front/src/designSystem/components/button';
import { PaginationCell } from 'common-front/src/designSystem/components/pagination/paginationCell';
import { PaginationRow } from 'common-front/src/designSystem/components/pagination/paginationRow';
import { RightPanel } from 'common-front/src/designSystem/components/rightPanel/rightPanel';
import { RightPanelBody } from 'common-front/src/designSystem/components/rightPanel/rightPanelBody';
import { RightPanelFooter } from 'common-front/src/designSystem/components/rightPanel/rightPanelFooter';
import { RightPanelHeader } from 'common-front/src/designSystem/components/rightPanel/rightPanelHeader';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { AvatarNameEmail } from 'common/src/components/avatarNameEmail';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { Row } from 'common/src/designSystem/components/table/row';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { Table } from 'common/src/designSystem/components/table/table';
import { SortDirection, VolunteersRegistrationsSortAttributes } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { Sort } from 'common/src/vo/sort';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { usePositionCategoryPositionVolunteersQuery } from '../../../generated/graphqlHooks';
import { UserOverlayEvent } from '../../../users/overlay/userOverlay';

interface IPositionCategoryPositionRightPanelProps {
    onClose(): void;
}

export const PositionCategoryPositionRightPanel = (
    props: IPositionCategoryPositionRightPanelProps
) => {
    const {
        history,
        translate,
        params: { organizationId, eventId, positionCategoryId, positionId }
    } = useHeavent();
    const [offset, setOffset] = React.useState(0);
    const [sort, setSort] = React.useState<Sort<VolunteersRegistrationsSortAttributes> | null>({
        direction: SortDirection.Asc,
        attribute: VolunteersRegistrationsSortAttributes.Name
    });
    const { data, isLoading, reload } = usePositionCategoryPositionVolunteersQuery({
        eventId,
        positionId,
        offset,
        sort
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.event?.volunteersRegistrations);
    const missingResources = React.useMemo(() => {
        if (data.event?.position) {
            return data.event.position.resources - data.event.position.assignedResources;
        } else {
            return 0;
        }
    }, [data.event?.position]);
    const _setSort = (newSort: Sort<VolunteersRegistrationsSortAttributes> | null) => {
        setOffset(0);
        setSort(newSort);
    };

    return (
        <>
            <RightPanel size="md" onClose={props.onClose}>
                <RightPanelHeader>
                    {isLoading ? (
                        <>
                            <Skeleton height={28} width={1} borderRadius="$1" />
                        </>
                    ) : (
                        <Box>{data.event.position.name}</Box>
                    )}
                </RightPanelHeader>

                <RightPanelBody>
                    <Spacer height="5" />

                    <Flex align="center" gap="2">
                        <Box>{translate('liste_des_membr_89037')}</Box>

                        {data.event ? (
                            missingResources > 0 ? (
                                <Badge color="warning">
                                    {translate('_1_membre_manq_31014', missingResources)}
                                </Badge>
                            ) : (
                                <Badge color="success">{translate('complet_51600')}</Badge>
                            )
                        ) : null}
                    </Flex>

                    <Spacer height="2" />

                    <Table>
                        <HeaderRow>
                            <HeaderCellSort
                                attribute={VolunteersRegistrationsSortAttributes.Name}
                                sort={sort}
                                setSort={_setSort}
                            >
                                {translate('nom_08311')}
                            </HeaderCellSort>
                        </HeaderRow>

                        {isLoading ? (
                            <>
                                <RowSkeleton bx={true} />
                                <RowSkeleton bx={true} />
                                <RowSkeleton bx={true} />
                            </>
                        ) : (
                            data.event.volunteersRegistrations.nodes.map((vr) => (
                                <Row
                                    key={vr.id}
                                    css={{
                                        cursor: 'pointer',
                                        '&:hover': {
                                            background: '$gray100'
                                        }
                                    }}
                                    onClick={() => {
                                        history.push(
                                            HeaventPaths.POSITION_MEMBERS_USER_INFORMATIONS(
                                                organizationId,
                                                eventId,
                                                positionCategoryId,
                                                positionId,
                                                vr.userInfo.id
                                            )
                                        );
                                    }}
                                >
                                    <Cell>
                                        <AvatarNameEmail userInfo={vr.userInfo} hasName={true} />
                                    </Cell>
                                </Row>
                            ))
                        )}

                        <PaginationRow>
                            <PaginationCell
                                numberOfPages={numberOfPages}
                                offset={offset}
                                totalCount={totalCount}
                                setOffset={setOffset}
                            />
                        </PaginationRow>
                    </Table>
                </RightPanelBody>

                <RightPanelFooter>
                    <Flex justify="end" gap="3">
                        <Button color="white" onClick={props.onClose}>
                            {translate('fermer_62479')}
                        </Button>
                    </Flex>
                </RightPanelFooter>
            </RightPanel>

            <Switch>
                <Route
                    path={HeaventPaths.POSITION_MEMBERS_USER(
                        ':organizationId',
                        ':eventId',
                        ':positionCategoryId',
                        ':positionId',
                        ':userInfoId'
                    )}
                >
                    <UserOverlayEvent
                        getBasePath={(userInfoId, isRouteComponent) =>
                            HeaventPaths.POSITION_MEMBERS_USER(
                                isRouteComponent ? ':organizationId' : organizationId,
                                isRouteComponent ? ':eventId' : eventId,
                                isRouteComponent ? ':positionCategoryId' : positionCategoryId,
                                isRouteComponent ? ':positionId' : positionId,
                                userInfoId
                            )
                        }
                        onClose={() => {
                            history.push(
                                HeaventPaths.POSITION_MEMBERS(
                                    organizationId,
                                    eventId,
                                    positionCategoryId,
                                    positionId
                                )
                            );
                        }}
                        onDelete={() => {
                            history.push(
                                HeaventPaths.POSITION_MEMBERS(
                                    organizationId,
                                    eventId,
                                    positionCategoryId,
                                    positionId
                                )
                            );

                            reload();
                        }}
                        onUpdateState={reload}
                    />
                </Route>
            </Switch>
        </>
    );
};
