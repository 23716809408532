import { sortBy, uniq } from 'lodash-es';
import * as React from 'react';
import { Avatar } from '../../../components/avatar/avatar';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyString } from '../../../util/string';

interface IBadgeMafiozikProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeMafiozik = (props: IBadgeMafiozikProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const positions = sortBy(
        uniq(
            vr.positionsSlotsUsersInfos
                .filter(
                    ({ positionCategory }) =>
                        positionCategory.id !== 14545 && positionCategory.id !== 14145
                )
                .map(({ position }) => position.name)
        )
    ).join(', ');
    const accreditations = sortBy(
        vr.accreditationsUsersInfos
            .map(({ accreditation }) => accreditation)
            .filter(({ acronym }) => isNonEmptyString(acronym)),
        (a) => a.name
    );
    const population = ui.mafiozikPopulation || 'Bénévole';

    return (
        <>
            <Flex
                height={324}
                width={204}
                css={{
                    background:
                        'url(https://assets.recrewteer.com/badges/mafiozik/Masque_Recto.png) no-repeat',
                    backgroundSize: 'cover',
                    pageBreakBefore: 'always',
                    position: 'relative'
                }}
            >
                <Flex
                    align="center"
                    justify="center"
                    height={17}
                    css={{
                        background:
                            population === 'Bénévole'
                                ? '#ec6134'
                                : population === 'Responsable'
                                ? '#f62238'
                                : '#638f4c',
                        color: 'black',
                        fontSize: '11px',
                        fontWeight: '700',
                        left: '19px',
                        position: 'absolute',
                        right: '19px',
                        textTransform: 'uppercase',
                        top: '98px',
                        width: 'calc(100% - 38px)'
                    }}
                >
                    {population}
                </Flex>

                {population && (
                    <Box
                        width={1}
                        css={{
                            color: 'white',
                            fontSize: '10px',
                            fontWeight: '500',
                            position: 'absolute',
                            textAlign: 'center',
                            top: '117px'
                        }}
                    >
                        {positions}
                    </Box>
                )}

                <Flex
                    justify="center"
                    width={1}
                    css={{
                        position: 'absolute',
                        top: '145px'
                    }}
                >
                    <Avatar size={64} name={ui.name} email={ui.email} image={ui.picture?.url} />
                </Flex>

                <Box
                    width={1}
                    css={{
                        color: 'white',
                        fontSize: '16px',
                        fontWeight: '700',
                        position: 'absolute',
                        textAlign: 'center',
                        top: '219px'
                    }}
                >
                    {ui.firstName}
                </Box>

                <Box
                    width={1}
                    css={{
                        color: 'white',
                        fontSize: '16px',
                        fontWeight: '700',
                        position: 'absolute',
                        textAlign: 'center',
                        top: '239px'
                    }}
                >
                    {ui.lastName}
                </Box>

                <Flex
                    justify="center"
                    width={1}
                    css={{
                        gap: '10px',
                        position: 'absolute',
                        top: '270px'
                    }}
                >
                    {accreditations.map((accreditation) => (
                            <Flex
                                key={accreditation.id}
                                align="center"
                                justify="center"
                                height={34}
                                width={34}
                                css={{
                                    background: accreditation.color,
                                    borderRadius: '6px',
                                    color: 'white',
                                    fontSize: '22px',
                                    fontWeight: '700'
                                }}
                            >
                                {accreditation.acronym}
                            </Flex>
                        ))}
                </Flex>
            </Flex>

            <Flex
                height={324}
                width={204}
                css={{
                    background:
                        'url(https://assets.recrewteer.com/badges/mafiozik/Masque_verso.png) no-repeat',
                    backgroundSize: 'cover',
                    pageBreakBefore: 'always'
                }}
            />
        </>
    );
};
