import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Cell } from 'common/src/designSystem/components/table/cell';
import {
    EventId,
    OrganizationId,
    SegmentCustomFieldFragment,
    UsersInfoId,
    VolunteerRegistrationFragment
} from 'common/src/generated/types';
import { PossibleColumn } from 'common/src/vo/segment';
import * as React from 'react';
import {
    useUserInfoUpdateFieldMutation,
    useVolunteerRegistrationUpdateFieldMutation
} from '../../../../generated/graphqlHooks';
import { UpdateCellDropdown } from './updateCellDropdown';

interface IUpdateCellProps<T> {
    children(value: T, setValue: (v: T) => void): React.ReactNode;
    field: PossibleColumn;
    initialValue: T;
    valueString: string;

    mutate(value: T): Promise<any>;
    reload(): void;
}

const UpdateCell = <T extends {}>(props: IUpdateCellProps<T>) => {
    const { isEditMode } = useSegmentsContext();
    const onSave = async (value: T) => {
        await props.mutate(value);

        props.reload();
    };

    if (isEditMode) {
        return (
            <UpdateCellDropdown
                initialValue={props.initialValue}
                renderValue={props.valueString}
                onSave={onSave}
            >
                {props.children}
            </UpdateCellDropdown>
        );
    } else {
        return (
            <Cell
                css={{
                    border: '2px solid transparent'
                }}
            >
                {props.valueString}
            </Cell>
        );
    }
};

interface IVolunteerRegistrationUpdateCellProps<T> {
    children(value: T, setValue: (v: T) => void): React.ReactNode;
    customField: SegmentCustomFieldFragment;
    eventId: EventId;
    field: PossibleColumn;
    initialValue: T;
    valueString: string;
    volunteerRegistration: VolunteerRegistrationFragment;

    reload(): void;
}

export const VolunteerRegistrationUpdateCell = <T extends {}>(
    props: IVolunteerRegistrationUpdateCellProps<T>
) => {
    const { mutate } = useVolunteerRegistrationUpdateFieldMutation();

    return (
        <UpdateCell
            {...props}
            mutate={(value) =>
                mutate({
                    eventId: props.eventId,
                    volunteerRegistrationId: props.volunteerRegistration.id,
                    slug: props.customField.slug,
                    value
                })
            }
        >
            {props.children}
        </UpdateCell>
    );
};

interface IUserInfoUpdateCellProps<T> {
    children(value: T, setValue: (v: T) => void): React.ReactNode;
    customField: SegmentCustomFieldFragment;
    field: PossibleColumn;
    initialValue: T;
    organizationId: OrganizationId;
    userInfoId: UsersInfoId;
    valueString: string;

    reload(): void;
}

export const UserInfoUpdateCell = <T extends {}>(props: IUserInfoUpdateCellProps<T>) => {
    const { mutate } = useUserInfoUpdateFieldMutation();

    return (
        <UpdateCell
            {...props}
            mutate={(value) =>
                mutate({
                    organizationId: props.organizationId,
                    userInfoId: props.userInfoId,
                    slug: props.customField.slug,
                    value
                })
            }
        >
            {props.children}
        </UpdateCell>
    );
};
