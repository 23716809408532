import { Authenticate } from 'common-front/src/authenticate';
import { Empty } from 'common-front/src/components/empty/empty';
import { MagicLink } from 'common-front/src/components/magicLink/magicLink';
import { Showcase } from 'common-front/src/designSystem/showcase/showcase';
import { Box } from 'common/src/designSystem/components/box';
import { overriddenTheme } from 'common/src/designSystem/components/stitches';
import { CommonEnvVars } from 'common/src/envVars';
import { HeaventEnv } from 'common/src/heaventEnv';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { OrganizationsPaths } from 'common/src/util/paths/organizationsPaths';
import * as React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { AccreditationMap } from './accreditations/map/accreditationMap';
import { Auth } from './auth/auth';
import { CognitoCallback } from './auth/cognitoCallback';
import { Backoffice } from './backoffice/backoffice';
import { CreateEventOrganization } from './events/create/createEventOrganization';
import { Home } from './home/home';
import { About } from './mso/about';
import { CreateOrganization } from './organization/create/createOrganization';
import { Organization } from './organization/organization';
import { ExportPositionsMap } from './positions/list/map/export/exportPositionsMap';
import { PositionMap } from './positions/map/positionMap';
import { RedirectToRegister } from './redirectToRegister';
import { UnsubscribeEmail } from './unsubscribeEmail';
import { UserFieldUpdate } from './userFieldUpdate';

const Authenticated = (props: { children: React.ReactNode }) => (
    <Authenticate shouldHaveUser={true} getRedirectPath={() => HeaventPaths.AUTH_SIGN_IN}>
        {props.children}
    </Authenticate>
);

const Unauthenticated = (props: { children: React.ReactNode }) => (
    <Authenticate shouldHaveUser={false} getRedirectPath={() => HeaventPaths.HOME}>
        {props.children}
    </Authenticate>
);

export const App = () => (
    <Box
        css={{
            color: '$gray800',
            height: '100%',
            overflow: 'hidden',
            position: 'relative',
            width: '100%'
        }}
        className={overriddenTheme}
    >
        <BrowserRouter>
            <Switch>
                <Route exact path={HeaventPaths.HOME}>
                    <Authenticated>
                        <Home />
                    </Authenticated>
                </Route>

                <Route
                    path={HeaventPaths.USER_FIELD_UPDATE(
                        ':organizationId',
                        ':userInfoId',
                        ':customFieldSlug',
                        ':value'
                    )}
                >
                    <UserFieldUpdate />
                </Route>

                <Route path={HeaventPaths.BACKOFFICE}>
                    <Authenticated>
                        <Backoffice />
                    </Authenticated>
                </Route>

                <Route path={HeaventPaths.DESIGN_SYSTEM}>
                    <Showcase />
                </Route>

                <Route path={HeaventPaths.POSITION_MAP(':eventId', ':positionId')}>
                    <PositionMap />
                </Route>

                <Route path={HeaventPaths.ACCREDITATION_MAP(':eventId', ':accreditationId')}>
                    <AccreditationMap />
                </Route>

                <Route path={HeaventPaths.EXPORT_POSITIONS_MAP(':eventId', ':segmentId')}>
                    <ExportPositionsMap />
                </Route>

                <Redirect
                    from={OrganizationsPaths.__Deprecated.CREATE_ORGANIZATION}
                    to={OrganizationsPaths.CREATE_ORGANIZATION}
                />
                <Route exact path={OrganizationsPaths.CREATE_ORGANIZATION}>
                    {' '}
                    <Authenticated>
                        <CreateOrganization />
                    </Authenticated>
                </Route>

                <Route exact path={HeaventPaths.CREATE_EVENT_WITH_ORGANIZATION}>
                    {' '}
                    <Authenticated>
                        <CreateEventOrganization />
                    </Authenticated>
                </Route>

                <Route path={HeaventPaths.ORGANIZATION(':organizationId')}>
                    {' '}
                    <Authenticated>
                        <Organization />
                    </Authenticated>
                </Route>

                <Route path={HeaventPaths.AUTH}>
                    {' '}
                    <Unauthenticated>
                        <Auth />
                    </Unauthenticated>
                </Route>

                <Route path={HeaventPaths.COGNITO_CALLBACK}>
                    {' '}
                    <Unauthenticated>
                        <CognitoCallback />
                    </Unauthenticated>
                </Route>

                <Route path={HeaventPaths.MAGIC_LINK(':code', ':source')}>
                    {' '}
                    <Unauthenticated>
                        <MagicLink />
                    </Unauthenticated>
                </Route>

                <Route path={HeaventPaths.UNSUBSCRIBE_EMAIL}>
                    <UnsubscribeEmail />
                </Route>

                {/* MSO specific routes */}

                {CommonEnvVars.HEAVENT_ENV === HeaventEnv.Mso && (
                    <>
                        <Route path="/about">
                            <About />
                        </Route>
                    </>
                )}

                {/* Handle old register routes */}
                <Route path="/volunteers/:organizationId-:eventId">
                    <RedirectToRegister isEmbed={false} />
                </Route>

                <Route path="/embed/volunteers/:organizationId-:eventId">
                    <RedirectToRegister isEmbed={true} />
                </Route>

                {/* Default redirect */}
                <Route>
                    <Empty path={HeaventPaths.HOME} />
                </Route>
            </Switch>
        </BrowserRouter>
    </Box>
);
